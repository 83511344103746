import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './local-dev-mfe.vue';
import { toast, ToastStatus, useQuasarPlugin } from '@mfl/common-components';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'local-dev',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);
    toast({
      aid: 'LOCAL_DEV_TOAST',
      message: 'Welcome',
      status: ToastStatus.Completed,
    });
  },
});

export { bootstrap, mount, unmount };
