<script setup lang="ts">
import type { HeaderWidgetProps } from './local-dev-header-widget.types.js';
const emit = defineEmits(['eventFromTheHeader']);
const props = defineProps<HeaderWidgetProps>();
</script>
<template>
  <div class="header-widget-container" @click="emit('eventFromTheHeader')">
    LOCAL DEV HEADER WIDGET ({{ props.clickMe }})
  </div>
</template>
<style lang="css" scoped>
.header-widget-container {
  border: 1px dotted black;
  padding: 5px;
  cursor: pointer;
}
</style>
