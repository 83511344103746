<script setup lang="ts">
// 3rd party
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { QBtn } from 'quasar';

// monorepo packages
import { Unsub } from '@mfl/framework';
import { WsButton } from '@mfl/common-components';
import { headerComponents } from '@mfe/core-header';

// local
import logo from './assets/local-dev-logo.jpg';
import localDevHeaderWidget from './local-dev-header-widget.vue';
import NavigationTester from './navigation-tester.vue';

const headerSay = ref('');

let removeHeaderComponent: Unsub | undefined;

onMounted(() => {
  removeHeaderComponent = headerComponents.register({
    key: 'local-dev-header-widget',
    component: localDevHeaderWidget,
    props: {
      clickMe: 'click me',
    },
    events: {
      eventFromTheHeader: () => {
        headerSay.value = `Last header event ${new Date()}`;
      },
    },
  });
});

onBeforeUnmount(() => {
  // Only do this if you wish to not have a memory leak
  if (removeHeaderComponent) removeHeaderComponent();
});
</script>
<template>
  <div class="local-dev-container">
    <h5>local dev (MFE)</h5>
    <WsButton
      color="promotion"
      class="local-dev-button"
      label="HOLA I'm a ws-button"
      aid="LOCAL_DEV_BUTTON"
    />
    <QBtn
      color="promotion"
      class="local-dev-button"
      label="I'm a quasar button"
    />
    <NavigationTester />
    <div>{{ headerSay }}</div>
    <img class="local-dev-logo" alt="local dev companion" :src="logo" />
  </div>
</template>
<style lang="css" scoped>
.local-dev-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f7;
}

.local-dev-button {
  align-self: center;
}

.local-dev-logo {
  align-self: flex-end;
  width: 150px;
  height: 150px;
}
</style>
